import React, {useState} from 'react';
import {Button} from "@mui/material";
import AddSchedulerModal from "./SchedulerModal/AddSchedulerModal";

const AddScheduler = ({fetchSchedulers, contactLists}) => {
    const [modalOpen, setModalOpen] = useState(false)

    const handleModalClose = () => {
        setModalOpen(false)
    }
    const handleModalOpen = () => {
        setModalOpen(true)
    }


    return (
        <>
            <Button onClick={() => handleModalOpen()} sx={{mb: 4}} variant={"contained"}>
                Add scheduler
            </Button>
            <AddSchedulerModal open={modalOpen} handleClose={handleModalClose} contactLists={contactLists} fetchSchedulers={fetchSchedulers}/>
        </>
    );
};

export default AddScheduler;