import React from 'react';
import {Box, Grid, Typography} from "@mui/material";
import Status from "../Status";

const JobChartLegend = ({color, count, itemName}) => {
    return (
        <Grid container alignContent={"center"}>
            <Grid item xs={2}>
                <Status size={18} color={color}/>
            </Grid>
            <Grid item>
                <Typography>
                    {itemName}: <b>{count}</b>
                </Typography>
            </Grid>
        </Grid>
    );
};

export default JobChartLegend;