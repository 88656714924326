import React, {useEffect, useState} from 'react';
import {Grid, Paper} from "@mui/material";
import {setSnack} from "../../store/actions/snack";
import {connect} from "react-redux";
import dayjs from "dayjs";
import {jobService} from "../../api/services/jobService";
import CenteredLoader from "../Loaders/CenteredLoader";
import JobsChart from "../Jobs/JobsChart";
import utc from 'dayjs/plugin/utc'; // import UTC plugin
dayjs.extend(utc);

const SchedulerDetails = ({scheduler, setSnack}) => {
    const [jobs, setJobs] = useState([])
    const [loadingJobs, setLoadingJobs] = useState(true)
    const [date, setDate] = useState(dayjs.utc())

    useEffect(() => {
        fetchJobs()
    }, [date])

    const fetchJobs = () => {
        setLoadingJobs(true)
        jobService.getJobs(scheduler.id, date)
            .then((response) => {
                setLoadingJobs(false);
                setJobs(response.data);
            })
            .catch((errorResponse) => {
                setLoadingJobs(false);
                setSnack("Could not load jobs", "error");
            });
    }

    const onDateChange = (newDate) => {
        setDate(newDate)
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <JobsChart loading={loadingJobs} jobs={jobs} date={date} onDateChange={onDateChange}/>
            </Grid>
        </Grid>
    );
};

const mapDispatchToProps = dispatch => {
    return {
        setSnack: (message, severity) => dispatch(setSnack(message, severity))
    };
};

export default connect(null, mapDispatchToProps)(SchedulerDetails);
