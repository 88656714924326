import React, {useState} from 'react';
import {Button} from "@mui/material";
import AddContactListModal from "./ContactListsModal/AddContactListModal";

const AddContactList = ({fetchContactLists, accounts}) => {
    const [modalOpen, setModalOpen] = useState(false)

    const handleModalClose = () => {
        setModalOpen(false)
    }
    const handleModalOpen = () => {
        setModalOpen(true)
    }


    return (
        <>
            <Button onClick={() => handleModalOpen()} sx={{mb: 4}} variant={"contained"}>
                Add Contact List
            </Button>
            <AddContactListModal open={modalOpen} handleClose={handleModalClose} fetchContactLists={fetchContactLists} accounts={accounts}/>
        </>
    );
};

export default AddContactList;