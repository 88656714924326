import React from 'react';
import {Box, CircularProgress, Grid, IconButton, Typography} from "@mui/material";
import {Delete, Edit} from "@mui/icons-material";
import EditAccount from "./EditAccount";
import DeleteAccount from "./DeleteAccount";

const AccountsListItem = ({account, fetchAccounts}) => {
    return (
        <Grid container item xs={12} alignItems={"center"} spacing={2}>
            <Grid item xs={1}>
                {account.id}
            </Grid>
            <Grid item xs={2}>
                {account.external_account_id}
            </Grid>
            <Grid item xs={6}>
                <Typography
                    sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        width: '100%',
                    }}
                >
                    {account.api_key}
                </Typography>
            </Grid>
            <Grid item xs={2}>
                <DeleteAccount fetchAccounts={fetchAccounts} account={account}/>
            </Grid>
            <Grid item xs={1}>
                <EditAccount fetchAccounts={fetchAccounts} account={account}/>
            </Grid>
        </Grid>
    );
};

export default AccountsListItem;