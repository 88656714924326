import React from 'react';
import {Box, useTheme} from "@mui/material";

const JobLine = ({progress, status}) => {
    const theme = useTheme()

    let color;

    if (status === -2){
        color = theme.palette.primary.main
    } else if (status === 3) {
        color = theme.palette.warning.main
    }else if (status === 0) {
        color = theme.palette.success.main
    } else {
        color = theme.palette.error.main
    }

    return (
        <Box sx={{
            width: 2,
            height: 50,
            borderRadius: 5,
            backgroundColor: color,
            position: "absolute",
            left: `${progress}%`
        }}/>
    );
};

export default JobLine;