import React from 'react';
import {Container, Divider, Grid} from "@mui/material";
import ContactListListItem from "./ContactListListItem";

const ContactListList = ({contactLists, fetchContactLists}) => {
    return (
        <Container maxWidth={"lg"}>
            <Grid container spacing={2}>
                <Grid item xs={1}>
                    ID
                </Grid>
                <Grid item xs={3}>
                    Maropost Account ID
                </Grid>
                <Grid item xs={3}>
                    Maropost List ID
                </Grid>
                <Grid item xs={4}>
                    Contacts Gathered
                </Grid>
                <Grid item xs={1}>
                </Grid>
                <Grid item xs={12}>
                    <Divider/>
                </Grid>
                <Grid container item xs={12} spacing={1}>
                    {contactLists.map((contactList, index) => {
                        return <ContactListListItem key={index} contactList={contactList}
                                                    fetchContactLists={fetchContactLists}/>
                    })}
                </Grid>
            </Grid>

        </Container>
    )
};

export default ContactListList;