import * as actionTypes from '../actions/actionTypes';
import {updateObject} from "../utility";

const initialState = {
    message: null,
    severity: null
};


const setSnack = (state, action) => {
    return updateObject(state, {
        message: action.message,
        severity: action.severity
    });
};

const removeSnack = (state, action) => {
    return updateObject(state, {
        message: null,
        severity: null
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_SNACK:
            return setSnack(state, action);
        case actionTypes.REMOVE_SNACK:
            return removeSnack(state, action);
        default:
            return state;
    }
};

export default reducer;