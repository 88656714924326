import React, {useState} from 'react';
import {Button} from "@mui/material";
import AddGatherersModal from "./GathererModal/AddGatherersModal";

const AddGatherers = ({fetchGatherers, contactLists}) => {
    const [modalOpen, setModalOpen] = useState(false)

    const handleModalClose = () => {
        setModalOpen(false)
    }
    const handleModalOpen = () => {
        setModalOpen(true)
    }


    return (
        <>
            <Button onClick={() => handleModalOpen()} sx={{mb: 4}} variant={"contained"}>
                Add gatherer
            </Button>
            <AddGatherersModal open={modalOpen} handleClose={handleModalClose} contactLists={contactLists} fetchGatherers={fetchGatherers}/>
        </>
    );
};

export default AddGatherers;