import React from 'react';
import {Container, Divider, Grid} from "@mui/material";
import SchedulerListItem from "./SchedulerListItem";

const SchedulersList = ({schedulers, fetchSchedulers, contactLists}) => {
    return (
        <Container maxWidth={"lg"}>
            <Grid container spacing={2} columns={24}>
                <Grid item xs={1}>
                    ID
                </Grid>
                <Grid item xs={4}>
                    Source List
                </Grid>
                <Grid item xs={4}>
                    Target List
                </Grid>
                <Grid item xs={2}>
                    Contacts
                </Grid>
                <Grid item xs={2}>
                    Start Time
                </Grid>
                <Grid item xs={2}>
                    End Time
                </Grid>
                <Grid item xs={4}>
                    Last Execution
                </Grid>
                <Grid item xs={2}>
                    Status
                </Grid>
                <Grid item xs={2}>

                </Grid>

                <Grid item xs={24}>
                    <Divider/>
                </Grid>
                <Grid container item xs={24} spacing={1}>
                    {schedulers.map((scheduler, index) => {
                        return <SchedulerListItem
                            key={index}
                            scheduler={scheduler}
                            fetchSchedulers={fetchSchedulers}
                            contactLists={contactLists}
                        />
                    })}
                </Grid>
            </Grid>

        </Container>
    )
};

export default SchedulersList;