import {useEffect, useState} from "react";
import {createTheme, CssBaseline, ThemeProvider} from "@mui/material";
import getDesignTokens from "./utils/designToken";
import {Outlet, Route, Routes, useNavigate} from "react-router-dom";
import Snack from "./components/Snack";
import {checkAuthState, clearAuthErrors, loginAction, setAuthRedirectPath} from "./store/actions/auth";
import {connect} from "react-redux";
import PrimaryNavigation from "./navigation/navigation/primaryNavigation";
import CenteredLoader from "./components/Loaders/CenteredLoader";
import FullPageLoader from "./components/Loaders/FullPageLoader";

const App = ({checkAuthState, clearAuthRedirectPath, authRedirectPath, authReady}) => {
    const [mode] = useState("dark")
    const theme = createTheme(getDesignTokens(mode))
    const navigate = useNavigate()

    useEffect(() => {
        checkAuthState()
    }, [])


    useEffect(() => {
        if (authRedirectPath) {
            navigate(authRedirectPath)
            clearAuthRedirectPath()
        }
    }, [authRedirectPath]);


    return (
        <ThemeProvider theme={theme}>

            <CssBaseline/>
            {
                authReady ?
                    <>
                        <PrimaryNavigation/>
                        <Snack/>
                    </> :
                    <FullPageLoader/>
            }

        </ThemeProvider>
    );
};


const mapStateToProps = state => {
    return {
        authRedirectPath: state.auth.authRedirectPath,
        isAuthenticated: !!state.auth.token,
        authReady: state.auth.authReady
    };
};


const mapDispatchToProps = dispatch => {
    return {
        clearAuthRedirectPath: () => dispatch(setAuthRedirectPath(undefined)),
        checkAuthState: () => dispatch(checkAuthState()),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(App);