import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';

import FullPageLoader from "../components/Loaders/FullPageLoader";

import {setSnack} from "../store/actions/snack";
import {contactListService} from "../api/services/contactListService";
import PageTitle from "../components/PageTitle";
import {gathererService} from "../api/services/gathererService";
import AddGatherers from "../components/Gatherers/AddGatherers";
import GatherersList from "../components/Gatherers/GatherersList";


// Other imports like FullPageLoader, EmptyGatherers, GatherersList based on your application structure.

const Gatherers = ({setSnack}) => {
    const [loadingContactLists, setLoadingContactLists] = useState(true);
    const [contactLists, setContactLists] = useState([]);
    const [loadingGatherers, setLoadingGatherers] = useState(true);
    const [gatherers, setGatherers] = useState([]);

    useEffect(() => {
        fetchGatherers()
    }, [setSnack]);

    useEffect(() => {
        fetchContactLists()
    }, [setSnack]);

    const fetchGatherers = () => {
        setLoadingGatherers(true)
        gathererService.getGatherers()
            .then((response) => {
                setLoadingGatherers(false);
                setGatherers(response.data);
            })
            .catch((errorResponse) => {
                setLoadingGatherers(false);
                setSnack(errorResponse.message, "error");
            });
    }

    const fetchContactLists = () => {
        setLoadingContactLists(true)
        contactListService.getLists()
            .then((response) => {
                setLoadingContactLists(false);
                setContactLists(response.data);
            })
            .catch((errorResponse) => {
                setLoadingContactLists(false);
                setSnack(errorResponse.message, "error");
            });
    }


    if (loadingGatherers || loadingContactLists) {
        return <FullPageLoader/>;
    }

    return (
        <>
            <PageTitle title={"Gatherers"}/>
            <AddGatherers fetchGatherers={fetchGatherers} contactLists={contactLists}/>
            <GatherersList gatherers={gatherers} fetchGatherers={fetchGatherers}/>
        </>
    );
};

const mapDispatchToProps = dispatch => {
    return {
        setSnack: (message, severity) => dispatch(setSnack(message, severity))
    };
};

export default connect(null, mapDispatchToProps)(Gatherers);
