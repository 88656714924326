import React, {useState} from 'react';
import {Button} from "@mui/material";
import AddAccountModal from "./AccountModal/AddAccountModal";

const AddAccount = ({fetchAccounts}) => {
    const [modalOpen, setModalOpen] = useState(false)

    const handleModalClose = () => {
        setModalOpen(false)
    }
    const handleModalOpen = () => {
        setModalOpen(true)
    }


    return (
        <>
            <Button onClick={() => handleModalOpen()} sx={{mb: 4}} variant={"contained"}>
                Add account
            </Button>
            <AddAccountModal open={modalOpen} handleClose={handleModalClose} fetchAccounts={fetchAccounts}/>
        </>
    );
};

export default AddAccount;