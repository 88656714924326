import React from 'react';
import {connect} from 'react-redux';
import {Alert, Snackbar} from "@mui/material";
import {removeSnack} from "../store/actions/snack";

const Snack = ({message, severity, removeSnack}) => {
    console.log(message)
    if (message) {
        return (
            <Snackbar open={true} autoHideDuration={6000} onClose={removeSnack}>
                <Alert onClose={removeSnack} severity={severity} sx={{width: '100%'}}>
                    {message}
                </Alert>
            </Snackbar>
        );
    }
    else {
        return null
    }
};


const mapStateToProps = state => {
    return {
        message: state.snack.message,
        severity: state.snack.severity,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        removeSnack: () => dispatch(removeSnack()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Snack);