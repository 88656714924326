import React from 'react';
import {Box, CircularProgress, Grid, IconButton} from "@mui/material";
import {Delete, Edit} from "@mui/icons-material";
import DeleteContactList from "./DeleteContactList";

const ContactListListItem = ({contactList, fetchContactLists}) => {
    return (
        <Grid container item xs={12}>
            <Grid item xs={1}>
                {contactList.id}
            </Grid>
            <Grid item xs={3}>
                {contactList.account.external_account_id}
            </Grid>
            <Grid item xs={3}>
                {contactList.external_list_id}
            </Grid>
            <Grid item xs={4}>
                {contactList.received}
            </Grid>
            <Grid item xs={1}>
                <DeleteContactList fetchContactLists={fetchContactLists} contactList={contactList}/>
            </Grid>
        </Grid>
    );
};

export default ContactListListItem;