import React from 'react';
import {Typography} from "@mui/material";

const PageTitle = ({title}) => {
    return (
        <Typography variant={"h4"} sx={{pb: 4}}>
            {title}
        </Typography>
    );
};

export default PageTitle;