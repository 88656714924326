const login = "/api/auth/login";
const register = "/api/auth/register";
const account = "/api/account"
const list = "/api/list"
const gatherer = "/api/gatherer"
const scheduler = "/api/scheduler"
const job = "/api/job"

export const endpoints = {
    login,
    register,
    account,
    list,
    gatherer,
    scheduler,
    job
}