import React from 'react';
import {Navigate, Route, Routes} from "react-router-dom";
import AppLayout from "../../components/AppLayout";
import Accounts from "../../pages/Accounts";
import Lists from "../../pages/Lists";
import Gatherers from "../../pages/Gatherers";
import Schedulers from "../../pages/Schedulers";


function HomePage() {
    return null;
}

const AppNavigation = () => {
    return (
        <Routes>
            <Route element={<AppLayout/>}>
                <Route index element={<HomePage/>}/>
                <Route path={"accounts"} element={<Accounts/>}/>
                <Route path={"lists"} element={<Lists/>}/>
                <Route path={"gatherers"} element={<Gatherers/>}/>
                <Route path={"schedulers"} element={<Schedulers/>}/>
            </Route>
        </Routes>
    )
};


export default AppNavigation;