const getDesignTokens = (mode) => ({
    palette: {
        mode,
        ...(mode === 'light'
            ? {
                // palette values for light mode
                // primary: amber,
                // divider: amber[200],
                // text: {
                //     primary: grey[900],
                //     secondary: grey[800],
                // },
            }
            : {
                // palette values for dark mode
                // primary: "#570000",
                background: {
                    default: "#181818",
                    paper: "#101010",
                },
                text: {
                    primary: '#fff',
                    secondary: "#858585",
                },
                primary: {
                    contrastText: "rgba(0, 0, 0, 0.87)",
                    dark: "#42a5f5",
                    light: "#e3f2fd",
                    main: "#90caf9"
                },
                secondary: {
                    contrastText: "rgba(0, 0, 0, 0.87)",
                    dark: "#94151c",
                    light: "#e3f2fd",
                    main: "#940107"
                }
            }),
    },

    components: {
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    // scrollbarColor: "#6b6b6b #2b2b2b",
                    // "&::-webkit-scrollbar": {
                    //     backgroundColor: "rgb(255,7,7)",
                    //     width: 30
                    // },
                    // "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
                    //     borderRadius: 8,
                    //     backgroundColor: mode === "dark" ? "#ff0000" : "#ff0000",
                    //     minHeight: 24,
                    //     border: "1px solid #2b2b2b",
                    // },
                    // "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus": {
                    //     backgroundColor: "#e10505",
                    // },
                    // "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active": {
                    //     backgroundColor: "#ea1313",
                    // },
                    // "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover": {
                    //     backgroundColor: "#ff0000",
                    // },
                    // "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
                    //     backgroundColor: mode === "dark" ? "#013aff" : "#ff0000",
                    // },
                },
            },
        },
        // MuiButton: {
        //     styleOverrides: {
        //         // Name of the slot
        //         root: {
        //             // Some CSS
        //             height: 50
        //         },
        //     },
        // },
    },
});

export default getDesignTokens;