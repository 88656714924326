import React, {useState} from 'react';
import {accountService} from "../../api/services/accountService";
import {setSnack} from "../../store/actions/snack";
import {setAuthRedirectPath} from "../../store/actions/auth";
import {connect} from "react-redux";
import {CircularProgress, IconButton} from "@mui/material";
import {Delete} from "@mui/icons-material";
import ButtonWithConfirmation from "../ButtonWithConfirmation";

const DeleteAccount = ({account, fetchAccounts}) => {
    const [loading, setLoading] = useState(false)

    const onClick = (data) => {
        setLoading(true)

        accountService.deleteAccount(account.id)
            .then((response) => {
                setSnack(response.message, "success")
                fetchAccounts()
            })
            .catch((error) => {
                setSnack(error.message, "error")
                setLoading(false)
            })

    }

    return (
        <ButtonWithConfirmation loading={loading} onConfirm={onClick} text={"Delete"}/>
    );
};

const mapDispatchToProps = dispatch => {
    return {
        setSnack: (message, severity) => dispatch(setSnack(message, severity)),
    };
};
export default connect(null, mapDispatchToProps)(DeleteAccount);