const login = "/auth/login"
const homePage = "/"
const accounts = "/accounts"
const lists = "/lists"
const gatherers = "/gatherers"
const schedulers = "/schedulers"

export const routes = {
    login,
    homePage,
    accounts,
    gatherers,
    lists,
    schedulers
}