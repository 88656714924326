import React from 'react';
import {Box, CircularProgress, Grid, IconButton, Typography} from "@mui/material";
import {Delete, Edit} from "@mui/icons-material";
import ToggleGathererState from "./ToggleGathererState";
import DeleteGatherer from "./DeleteGatherer";
import Status from "../Status";


const GatherersListItem = ({gatherer, fetchGatherers}) => {
    return (
        <Grid container item xs={12} alignItems={"center"} spacing={2}>
            <Grid item xs={1}>
                {gatherer.id}
            </Grid>
            <Grid item xs={3}>
                <Typography
                    sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        width: '100%',
                    }}
                >
                    {`List ${gatherer.source_list.external_list_id} of Account ${gatherer.source_list.account.external_account_id}`}
                </Typography>
            </Grid>
            <Grid item xs={1}>
                {gatherer.active ?
                    <Status size={24} color={"success.main"}/> :
                    <Status size={24} color={"error.main"}/>
                }
            </Grid>
            <Grid item xs={3}>
                {gatherer.last_execution}
            </Grid>
            <Grid item xs={2}>
                <ToggleGathererState gatherer={gatherer} fetchGatherers={fetchGatherers}/>
            </Grid>
            <Grid item xs={2}>
                <DeleteGatherer fetchGatherers={fetchGatherers} gatherer={gatherer}/>
            </Grid>

        </Grid>
    );
};

export default GatherersListItem;