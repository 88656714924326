import React, {useState} from 'react';
import {useForm} from "react-hook-form";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {connect} from "react-redux";
import {setSnack} from "../../../store/actions/snack";
import {Button, Container, Grid, IconButton, Modal, Paper, Typography} from "@mui/material";

import {Close} from "@mui/icons-material";
import ControlledTextField from "../../ControlledTextField";
import SubmitButton from "../../SubmitButton";
import {setAuthRedirectPath} from "../../../store/actions/auth";
import ControlledSelectField from "../../ControlledSelectField";
import {schedulerService} from "../../../api/services/schedulerService";
import ControlledTimePickerField from "../../ControlledTimePickerField";

const schema = yup.object().shape({
    source_list_id: yup.number().typeError('Must be a number').required('Required field').min(1, 'Required'),
    target_list_id: yup.number().typeError('Must be a number').required('Required field').min(1, 'Required'),
    target_items: yup.number().typeError('Must be a number').required('Required field').min(1, 'Required'),
    start_time: yup.string().required('Required field'),
    end_time: yup.string().required('Required field'),
});

const AddSchedulerModal = ({setSnack, open, handleClose, fetchSchedulers, contactLists}) => {
    const [loading, setLoading] = useState(false)
    const {
        control,
        handleSubmit,
        reset
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            source_list_id: "",
            target_list_id: "",
            target_items: "",
            start_time: null,
            end_time: null
        }
    })

    const listsOptions = contactLists.map((contactList) => {
        return {
            key: `List ${contactList.external_list_id} of Account ${contactList.account.external_account_id}`,
            value: contactList.id
        }
    })

    const onSuccess = (data) => {
        setLoading(true)

        schedulerService.createScheduler(
            data.source_list_id,
            data.target_list_id,
            data.target_items,
            data.start_time,
            data.end_time
        )
            .then((response) => {
                setSnack(response.message, "success")
                setLoading(false)

                onClose()
                fetchSchedulers()
            })
            .catch((error) => {
                setSnack(error.message, "error")
                setLoading(false)
            })

    }

    const onError = (error) => {
        // Handle Errors
        console.log(error)
    }

    const onClose = () => {
        reset({
            source_list_id: "",
            target_list_id: "",
            target_items: "",
            start_time: null,
            end_time: null
        }, {keepErrors:false})
        handleClose()
    }

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{overflow: "scroll"}}
        >
            <Container maxWidth={"sm"} component={Paper} sx={{my: 4, py: 2}}>
                <form onSubmit={handleSubmit(onSuccess, onError)}>
                    <Grid container alignItems={"center"} columns={24}>
                        <Grid item xs={22} md={23}>
                            <Typography variant={"h4"} sx={{my: 2}}>
                                Create Scheduler
                            </Typography>
                        </Grid>
                        <Grid item xs={2} md={1}>
                            <IconButton sx={{margin: "auto"}} onClick={() => handleClose()}>
                                <Close/>
                            </IconButton>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <ControlledSelectField control={control} name={"source_list_id"} label={"Source List"}
                                                   options={listsOptions}/>
                        </Grid>
                        <Grid item xs={6}>
                            <ControlledSelectField control={control} name={"target_list_id"} label={"Target List"}
                                                   options={listsOptions}/>
                        </Grid>
                        <Grid item xs={4}>
                            <ControlledTextField control={control} name={"target_items"} label={"Contacts to Send"}/>
                        </Grid>
                        <Grid item xs={4}>
                            {/*<ControlledTextField control={control} name={"start_time"} label={"Start Time"}/>*/}
                            <ControlledTimePickerField control={control} name={"start_time"} label={"Start Time"}/>
                        </Grid>
                        <Grid item xs={4}>
                            <ControlledTimePickerField control={control} name={"end_time"} label={"End Time"}/>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={{mt: 3}}>
                        <Grid item xs={6}>
                            <Button
                                onClick={() => handleClose()}
                                fullWidth
                                variant={"outlined"}
                            >
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <SubmitButton
                                text={"SUBMIT"}
                                loading={loading}
                            />
                        </Grid>

                    </Grid>
                </form>
            </Container>
        </Modal>

    );
};

const mapDispatchToProps = dispatch => {
    return {
        setSnack: (message, severity) => dispatch(setSnack(message, severity)),
        setAuthRedirectPath: (path) => setAuthRedirectPath(path)
    };
};
export default connect(null, mapDispatchToProps)(AddSchedulerModal);