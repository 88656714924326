import * as actionTypes from './actionTypes';

export const setSnack = (message, severity) => {
    return {
        type: actionTypes.SET_SNACK,
        message,
        severity
    };
};

export const removeSnack = () => {
    return {
        type: actionTypes.REMOVE_SNACK,
    };
}
