import React, {useState} from 'react';
import {useForm} from "react-hook-form";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {accountService} from "../../../api/services/accountService";
import {connect} from "react-redux";
import {setSnack} from "../../../store/actions/snack";
import {Button, Container, Grid, IconButton, InputLabel, Modal, Paper, Typography} from "@mui/material";

import {Close} from "@mui/icons-material";
import ControlledTextField from "../../ControlledTextField";
import SubmitButton from "../../SubmitButton";
import {setAuthRedirectPath} from "../../../store/actions/auth";
import {routes} from "../../../utils/routes";
import {gathererService} from "../../../api/services/gathererService";
import ControlledSelectField from "../../ControlledSelectField";
import {schedulerService} from "../../../api/services/schedulerService";
import DeleteScheduler from "../DeleteScheduler";
import {fromHoursToDateTime} from "../../../utils/dateUtils";
import ControlledTimePickerField from "../../ControlledTimePickerField";

const schema = yup.object().shape({
    target_items: yup.number().typeError('Must be a number').required('Required field').min(1, 'Required'),
    start_time: yup.string().required('Required field'),
    end_time: yup.string().required('Required field'),
    active: yup.bool().required('Required field')
});

const EditSchedulerModal = ({scheduler, setSnack, open, handleClose, fetchSchedulers, contactLists}) => {
    const [loading, setLoading] = useState(false)
    const {
        control,
        handleSubmit,
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            source_list_id: scheduler.source_list.id,
            target_list_id: scheduler.target_list.id,
            target_items: scheduler.target_items,
            start_time: fromHoursToDateTime(scheduler.start_time),
            end_time: fromHoursToDateTime(scheduler.end_time),
            active: scheduler.active
        }
    })

    const onSuccess = (data) => {
        setLoading(true)

        schedulerService.updateScheduler(
            scheduler.id,
            data.source_list_id,
            data.target_list_id,
            data.target_items,
            data.start_time,
            data.end_time,
            data.active
        )
            .then((response) => {
                setSnack(response.message, "success")
                setLoading(false)
                handleClose()
                fetchSchedulers()
            })
            .catch((error) => {
                setSnack(error.message, "error")
                setLoading(false)
            })

    }

    const onError = (error) => {
        // Handle Errors
        console.log(error)
    }

    return (
        <Modal
            open={open}
            onClose={() => handleClose()}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{overflow: "scroll"}}
        >
            <Container maxWidth={"sm"} component={Paper} sx={{my: 4, py: 2}}>
                <form onSubmit={handleSubmit(onSuccess, onError)}>
                    <Grid container alignItems={"center"} columns={24}>
                        <Grid item xs={22} md={23}>
                            <Typography variant={"h4"} sx={{my: 2}}>
                                Edit Scheduler
                            </Typography>
                        </Grid>
                        <Grid item xs={2} md={1}>
                            <IconButton sx={{margin: "auto"}} onClick={() => handleClose()}>
                                <Close/>
                            </IconButton>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <InputLabel>
                                Source
                            </InputLabel>
                            <Typography>
                                {`List ${scheduler.source_list.external_list_id} of Account ${scheduler.source_list.account.external_account_id}`}
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <InputLabel>
                                Target
                            </InputLabel>
                            <Typography>
                                {`List ${scheduler.target_list.external_list_id} of Account ${scheduler.target_list.account.external_account_id}`}
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <ControlledTextField control={control} name={"target_items"} label={"Contacts to Send"}/>
                        </Grid>
                        <Grid item xs={3}>
                            <ControlledTimePickerField control={control} name={"start_time"} label={"Start Time"}/>
                        </Grid>
                        <Grid item xs={3}>
                            <ControlledTimePickerField control={control} name={"end_time"} label={"EndT ime"}/>
                        </Grid>
                        <Grid item xs={3}>
                            <ControlledSelectField control={control} name={"active"} label={"Status"}
                                                   options={[
                                                       {
                                                           key: "Active",
                                                           value: true
                                                       },
                                                       {
                                                           key: "Inactive",
                                                           value: false
                                                       },
                                                   ]}/>
                        </Grid>

                    </Grid>
                    <Grid container spacing={2} sx={{mt: 3}}>
                        <Grid item xs={4}>
                            <DeleteScheduler fetchSchedulers={fetchSchedulers} scheduler={scheduler}/>
                        </Grid>
                        <Grid item xs={4}>
                            <Button
                                onClick={() => handleClose()}
                                fullWidth
                                variant={"outlined"}
                            >
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item xs={4}>
                            <SubmitButton
                                text={"SUBMIT"}
                                loading={loading}
                            />
                        </Grid>

                    </Grid>
                </form>
            </Container>
        </Modal>

    );
};

const mapDispatchToProps = dispatch => {
    return {
        setSnack: (message, severity) => dispatch(setSnack(message, severity)),
        setAuthRedirectPath: (path) => setAuthRedirectPath(path)
    };
};
export default connect(null, mapDispatchToProps)(EditSchedulerModal);