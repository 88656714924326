import React, {useEffect, useRef, useState} from 'react';
import {Box, Grid} from "@mui/material";
import {getDayProgress} from "../../utils/dateUtils";
import dayjs from "dayjs";
import JobLine from "./JobLine";
import CurrentTimeJobLine from "./CurrentTimeJobLine";
import utc from 'dayjs/plugin/utc';
import JobChartLegend from "./JobChartLegend"; // import UTC plugin
dayjs.extend(utc);
const JobsTimeline = ({jobs, date}) => {

    const containerRef = useRef(null);

    const successfulJobs = []
    const pendingJobs = []
    const errorJobs = []
    const skippedJobs = []

    for (let job of jobs) {
        if (job.status === -2) {
            pendingJobs.push(job)
        } else if (job.status === 3) {
            skippedJobs.push(job)
        } else if (job.status === 0) {
            successfulJobs.push(job)
        } else {
            errorJobs.push(job)
        }
    }

    return (
        <Box sx={{pt: 2}}>
            <Box
                sx={{
                    width: "100%",
                    height: 50,
                    backgroundColor: "#4d4d4d",
                    borderRadius: 2,
                    position: "relative",
                    // my:2
                }}
                ref={containerRef}
            >
                {
                    jobs.map((job, index) => {
                        const jobProgress = getDayProgress(dayjs(job.start_time))
                        return <JobLine key={index} progress={jobProgress} status={job.status}/>
                    })
                }
                {date.isSame(dayjs(), 'day') && <CurrentTimeJobLine progress={getDayProgress(dayjs.utc())}/>}

            </Box>
            <Grid container textAlign={"center"} sx={{pt: 2}}>
                <Grid item xs={1}>
                    1:00
                </Grid>
                <Grid item xs={1}>
                    3:00
                </Grid>
                <Grid item xs={1}>
                    5:00
                </Grid>
                <Grid item xs={1}>
                    7:00
                </Grid>
                <Grid item xs={1}>
                    9:00
                </Grid>
                <Grid item xs={1}>
                    11:00
                </Grid>
                <Grid item xs={1}>
                    13:00
                </Grid>
                <Grid item xs={1}>
                    15:00
                </Grid>
                <Grid item xs={1}>
                    17:00
                </Grid>
                <Grid item xs={1}>
                    19:00
                </Grid>
                <Grid item xs={1}>
                    21:00
                </Grid>
                <Grid item xs={1}>
                    23:00
                </Grid>
            </Grid>
            <Grid container textAlign={"center"} sx={{pt: 2}}>
                <Grid item xs={2}>
                </Grid>
                <Grid item xs={2}>
                    <JobChartLegend color={"primary.main"} itemName={"Pending"} count={pendingJobs.length}/>
                </Grid>
                <Grid item xs={2}>
                    <JobChartLegend color={"success.main"} itemName={"Success"} count={successfulJobs.length}/>
                </Grid>
                <Grid item xs={2}>
                    <JobChartLegend color={"warning.main"} itemName={"Skipped"} count={skippedJobs.length}/>
                </Grid>
                <Grid item xs={2}>
                    <JobChartLegend color={"error.main"} itemName={"Error"} count={errorJobs.length}/>
                </Grid>
                <Grid item xs={2}>
                </Grid>
            </Grid>
        </Box>

    );
};

export default JobsTimeline;