import buildClient from "../buildClient";
import {endpoints} from "../endpoints";

const createAccount = (external_account_id, api_key) => {
    return new Promise((resolve, reject) => {
        const apiClient = buildClient()

        const data = {
            external_account_id,
            api_key
        }

        apiClient.post(endpoints.account, data)
            .then((response) => {
                resolve(response.data)
            })
            .catch((errorResponse) => {
                reject(errorResponse.data)
            })
    })
}

const getAccounts = () => {
    return new Promise((resolve, reject) => {
        const apiClient = buildClient()

        apiClient.get(endpoints.account)
            .then((response) => {
                resolve(response.data)
            })
            .catch((errorResponse) => {
                reject(errorResponse.data)
            })
    })
}

const updateAccount = (account_id, api_key) => {
    return new Promise((resolve, reject) => {
        const apiClient = buildClient()

        const data = {
            api_key
        }

        apiClient.put(`${endpoints.account}/${account_id}`, data)
            .then((response) => {
                resolve(response.data)
            })
            .catch((errorResponse) => {
                reject(errorResponse.data)
            })
    })
}

const deleteAccount = (account_id) => {
    return new Promise((resolve, reject) => {
        const apiClient = buildClient()

        apiClient.delete(`${endpoints.account}/${account_id}`)
            .then((response) => {
                resolve(response.data)
            })
            .catch((errorResponse) => {
                reject(errorResponse.data)
            })
    })
}

export const accountService = {
    createAccount,
    getAccounts,
    updateAccount,
    deleteAccount
}
