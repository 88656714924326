import React from 'react';
import {Box} from "@mui/material";

const CurrentTimeJobLine = ({progress}) => {
    return (
        <Box sx={{
            width: 2,
            height: 80,
            backgroundColor: "blue",
            position: "absolute",
            borderRadius: 15,
            left: `${progress}%`,
            top: -15
        }}/>
    );
};

export default CurrentTimeJobLine;