import * as yup from "yup";
import {yupResolver} from '@hookform/resolvers/yup';
import {connect, useSelector} from "react-redux";

import {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import AuthLayout from "../components/Auth/AuthLayout";
import AuthForm from "../components/Auth/AuthForm";
import AuthTitle from "../components/Auth/AuthTitle";
import AuthTextFieldArea from "../components/Auth/AuthTextFieldArea";
import ControlledTextField from "../components/ControlledTextField";
import ControlledPasswordField from "../components/ControlledPasswordField";
import SubmitButton from "../components/SubmitButton";
import {clearAuthErrors, loginAction} from "../store/actions/auth";


const schema = yup.object().shape({
    email: yup.string().email("Must be valid email").required('Required field'),
    password: yup.string().required('Required field').min(8, 'Must be at least 8 characters long'),
});


const Login = ({login, clearAuthErrors, errors, authLoading}) => {
    const [showPassword, setShowPassword] = useState(false)
    const {
        control,
        handleSubmit,
        setError,
        clearErrors
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            email: "",
            password: ""
        }
    });

    const onSuccess = (data) => {
        login(data)
    }

    const onError = (error) => {
        // Handle Errors
        console.log(error)
    }

    useEffect(() => {
        clearAuthErrors()
    }, [])

    useEffect(() => {
        for (let fieldName in schema) {
            if (schema.hasOwnProperty(fieldName)) {
                if (errors[fieldName])
                    setError(fieldName, {type: "custom", message: errors[fieldName]})
                else
                    clearErrors(fieldName)
            }
        }
    }, [errors, setError, clearErrors])

    return (
        <AuthLayout>
            <AuthForm onSubmit={handleSubmit(onSuccess, onError)}>

                <AuthTitle title={"Login"}/>
                <AuthTextFieldArea>
                    <ControlledTextField control={control} name={"email"} label={"Email"}/>
                    <ControlledPasswordField control={control} name={"password"} label={"Password"}
                                             setShowPassword={setShowPassword} showPassword={showPassword}/>
                </AuthTextFieldArea>

                <SubmitButton text={"Login"} onSubmit={null} loading={authLoading}/>

            </AuthForm>
        </AuthLayout>
    )
}

const mapStateToProps = state => {
    return {
        errors: state.auth.errors,
        authLoading: state.auth.loading,
    };
};


const mapDispatchToProps = dispatch => {
    return {
        login: (data) => dispatch(loginAction(data)),
        clearAuthErrors: () => dispatch(clearAuthErrors())
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Login);