import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';

import FullPageLoader from "../components/Loaders/FullPageLoader";

import {setSnack} from "../store/actions/snack";
import {contactListService} from "../api/services/contactListService";
import PageTitle from "../components/PageTitle";
import {accountService} from "../api/services/accountService";
import ContactListList from "../components/ContactLists/ContactListList";
import AddContactList from "../components/ContactLists/AddContactList";


// Other imports like FullPageLoader, EmptyContactList, ContactListList based on your application structure.

const ContactList = ({setSnack}) => {
    const [loadingContactList, setLoadingContactList] = useState(true);
    const [contactLists, setContactLists] = useState([]);
    const [loadingAccounts, setLoadingAccounts] = useState(true);
    const [accounts, setAccounts] = useState([]);

    useEffect(() => {
        fetchAccounts()
    }, [setSnack]);

    useEffect(() => {
        fetchContactLists()
    }, [setSnack]);

    const fetchAccounts = () => {
        setLoadingAccounts(true)
        accountService.getAccounts()
            .then((response) => {
                setLoadingAccounts(false);
                setAccounts(response.data);
            })
            .catch((errorResponse) => {
                setLoadingAccounts(false);
                setSnack(errorResponse.message, "error");
            });
    }

    const fetchContactLists = () => {
        setLoadingContactList(true)
        contactListService.getLists()
            .then((response) => {
                setLoadingContactList(false);
                setContactLists(response.data);
            })
            .catch((errorResponse) => {
                setLoadingContactList(false);
                setSnack(errorResponse.message, "error");
            });
    }

    if (loadingContactList || loadingAccounts) {
        return <FullPageLoader/>;
    }

    return (
        <>
            <PageTitle title={"Lists"}/>
            <AddContactList fetchContactLists={fetchContactLists} accounts={accounts}/>
            <ContactListList contactLists={contactLists} fetchContactLists={fetchContactLists}/>
        </>
    );
};

const mapDispatchToProps = dispatch => {
    return {
        setSnack: (message, severity) => dispatch(setSnack(message, severity))
    };
};

export default connect(null, mapDispatchToProps)(ContactList);
