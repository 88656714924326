import React from 'react';
import {Box, Divider, Drawer, List, ListItem, ListItemButton, ListItemText, Toolbar} from "@mui/material";
import {Link} from "react-router-dom";
import {drawerWidth} from "../../utils/drawerSettings";

const CustomDrawer = ({mobileOpen, handleDrawerToggle, navItems}) => {

    const container = window !== undefined ? () => window.document.body : undefined;

    return (
        <Box component="nav">
            <Drawer
                // container={container}
                variant="permanent"
                open={true}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                    display: {xs: 'block'},
                    '& .MuiDrawer-paper': {boxSizing: 'border-box', width: drawerWidth},
                }}
            >
                <Box onClick={handleDrawerToggle} sx={{textAlign: 'center'}}>
                    <Toolbar/>
                    <List>
                        {navItems.map((item, index) => (
                            <ListItem key={index} disablePadding>
                                <ListItemButton sx={{textAlign: 'center'}} component={Link} to={item.path}>
                                    <ListItemText primary={item.label}/>
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                </Box>
            </Drawer>
        </Box>

    );
};

export default CustomDrawer;