import React, {useEffect} from 'react';
import {Navigate, Route, Routes, useNavigate} from "react-router-dom";

import {Outlet} from "react-router-dom";
import {connect} from "react-redux";
import {routes} from "../../utils/routes";
import {setSnack} from "../../store/actions/snack";
import Logout from "../../pages/Logout";
import Login from "../../pages/Login";

const AuthNavigation = ({isAuthenticated, setSnack}) => {

    const navigate = useNavigate()

    useEffect(() => {
        if (isAuthenticated) {
            // setSnack("You are already logged in!", "warning")
            navigate(routes.homePage)
        }
    }, [isAuthenticated])

    return (
        <Routes>
            <Route element={<Outlet/>}>
                <Route path={"logout"} element={<Logout/>}/>
                <Route path={"login"} element={<Login/>}/>

            </Route>
        </Routes>
    );
};

const mapStateToProps = state => {
    return {
        isAuthenticated: !!state.auth.token,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setSnack: (message, severity) => dispatch(setSnack(message, severity)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(AuthNavigation);