import React from 'react';
import {connect} from "react-redux";
import {
    AppBar,
    Box,
    Button,
    Divider,
    Drawer,
    IconButton,
    Link,
    List,
    ListItem,
    ListItemButton, ListItemText,
    Toolbar
} from "@mui/material";
import {Menu} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";
import {routes} from "../../utils/routes";
import CustomDrawer from "./CustomDrawer";
import CustomAppBar from "./CustomAppBar";

const drawerWidth = 240;

const NavigationBar = ({isAuthenticated, ...props}) => {

    const {window} = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);


    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    let navItems = [
        {
            label: "Dashboard",
            path: routes.homePage
        },
        {
            label: "Accounts",
            path: routes.accounts
        },
        {
            label: "Lists",
            path: routes.lists
        },
        {
            label: "Gatherers",
            path: routes.gatherers
        },
        {
            label: "Schedulers",
            path: routes.schedulers
        }
    ];


    return (
        <Box>
            <CustomDrawer mobileOpen={mobileOpen} handleDrawerToggle={handleDrawerToggle} navItems={navItems}/>
        </Box>
    );
};

const mapStateToProps = state => {
    return {
        isAuthenticated: state.auth.token,
    };
};

export default connect(mapStateToProps)(NavigationBar);