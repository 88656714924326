import buildClient from "../buildClient";
import {endpoints} from "../endpoints";
import {fromDateToHours} from "../../utils/dateUtils";


const createScheduler = (source_list_id, target_list_id, target_items, start_time, end_time) => {
    return new Promise((resolve, reject) => {
        const apiClient = buildClient();

        const data = {
            source_list_id,
            target_list_id,
            target_items,
            start_time: fromDateToHours(start_time),
            end_time: fromDateToHours(end_time)
        };

        apiClient.post(endpoints.scheduler, data)
            .then((response) => {
                resolve(response.data);
            })
            .catch((errorResponse) => {
                reject(errorResponse.data);
            });
    });
};

const getSchedulers = () => {
    return new Promise((resolve, reject) => {
        const apiClient = buildClient();

        apiClient.get(endpoints.scheduler)
            .then((response) => {
                resolve(response.data);
            })
            .catch((errorResponse) => {
                reject(errorResponse.data);
            });
    });
};

const updateScheduler = (
    id,
    source_list_id,
    target_list_id,
    target_items,
    start_time,
    end_time,
    active
) => {
    return new Promise((resolve, reject) => {
        const apiClient = buildClient();

        const data = {
            source_list_id,
            target_list_id,
            target_items,
            start_time: fromDateToHours(start_time),
            end_time: fromDateToHours(end_time),
            active,
        };

        apiClient.put(`${endpoints.scheduler}/${id}`, data)
            .then((response) => {
                resolve(response.data);
            })
            .catch((errorResponse) => {
                reject(errorResponse.data);
            });
    });
};

const deleteScheduler = (id) => {
    return new Promise((resolve, reject) => {
        const apiClient = buildClient();

        apiClient.delete(`${endpoints.scheduler}/${id}`)
            .then((response) => {
                resolve(response.data);
            })
            .catch((errorResponse) => {
                reject(errorResponse.data);
            });
    });
};

export const schedulerService = {
    createScheduler,
    getSchedulers,
    updateScheduler,
    deleteScheduler,
};
