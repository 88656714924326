import React from 'react';
import {Container, Divider, Grid} from "@mui/material";
import GatherersListItem from "./GatherersListItem";

const GatherersList = ({gatherers, fetchGatherers}) => {
    return (
        <Container maxWidth={"lg"}>
            <Grid container spacing={2}>
                <Grid item xs={1}>
                    ID
                </Grid>
                <Grid item xs={3}>
                    List Info
                </Grid>
                <Grid item xs={1}>
                    Status
                </Grid>
                <Grid item xs={3}>
                    Last Execution
                </Grid>
                <Grid item xs={2}>

                </Grid>
                <Grid item xs={12}>
                    <Divider/>
                </Grid>
                <Grid container item xs={12} spacing={1}>
                    {gatherers.map((gatherer, index) => {
                        return <GatherersListItem
                            key={index}
                            gatherer={gatherer}
                            fetchGatherers={fetchGatherers}
                        />
                    })}
                </Grid>
            </Grid>

        </Container>
    )
};

export default GatherersList;