import React, {useState} from 'react';
import {setSnack} from "../../store/actions/snack";
import {connect} from "react-redux";
import ButtonWithConfirmation from "../ButtonWithConfirmation";
import {contactListService} from "../../api/services/contactListService";

const DeleteContactList = ({contactList, fetchContactLists}) => {
    const [loading, setLoading] = useState(false)

    const onClick = (data) => {
        setLoading(true)

        contactListService.deleteList(contactList.id)
            .then((response) => {
                setSnack(response.message, "success")
                fetchContactLists()
            })
            .catch((error) => {
                setSnack(error.message, "error")
                setLoading(false)
            })

    }

    return (
        <ButtonWithConfirmation loading={loading} onConfirm={onClick} text={"Delete"}/>
    );
};

const mapDispatchToProps = dispatch => {
    return {
        setSnack: (message, severity) => dispatch(setSnack(message, severity)),
    };
};
export default connect(null, mapDispatchToProps)(DeleteContactList);