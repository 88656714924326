import React, {useState} from 'react';
import {accountService} from "../../api/services/accountService";
import {setSnack} from "../../store/actions/snack";
import ButtonWithConfirmation from "../ButtonWithConfirmation";
import {connect} from "react-redux";
import {gathererService} from "../../api/services/gathererService";
import {Button, CircularProgress} from "@mui/material";

const ToggleSchedulerState = ({gatherer, fetchGatherers}) => {
    const [loading, setLoading] = useState(false)

    const onClick = () => {
        setLoading(true)

        gathererService.updateGatherer(gatherer.id, !gatherer.active)
            .then((response) => {
                setSnack(response.message, "success")
                fetchGatherers()
            })
            .catch((error) => {
                setSnack(error.message, "error")
                setLoading(false)
            })

    }

    return (
        <Button
            type={"button"}
            fullWidth
            disabled={loading}
            variant={"outlined"}
            color={"warning"}
            onClick={() => onClick()}


        >
            {loading ? <>
                    <CircularProgress size={24}/>
                    &nbsp;
                </>
                : gatherer.active ? "Deactivate" : "Activate" }
        </Button>
    );
};

const mapDispatchToProps = dispatch => {
    return {
        setSnack: (message, severity) => dispatch(setSnack(message, severity)),
    };
};
export default connect(null, mapDispatchToProps)(ToggleSchedulerState);