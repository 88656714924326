import buildClient from "../buildClient";
import {endpoints} from "../endpoints";
import {formatDate} from "../../utils/dateUtils";


const getJobs = (scheduler_id, date) => {
    return new Promise((resolve, reject) => {
        const apiClient = buildClient();

        const data = {
            scheduler_id,
            date: formatDate(date)
        }

        apiClient.get(endpoints.job, {params: data})
            .then((response) => {
                resolve(response.data);
            })
            .catch((errorResponse) => {
                reject(errorResponse.data);
            });
    });
};

export const jobService = {
    getJobs
};
