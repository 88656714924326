import React from 'react';
import {Controller} from "react-hook-form";
import {FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField} from "@mui/material";

const ControlledSelectField = ({control, name, label, options, inputProps = null, disabled=false}) => {
    return (
        <Controller
            name={name}
            control={control}
            render={({field: {onChange, value}, fieldState: {error}}) => (
                <TextField
                    fullWidth
                    select
                    label={label}
                    value={value}
                    error={!!error}
                    helperText={error ? error.message : " "}
                    onChange={onChange}
                    variant={"standard"}
                >
                    {options.map((option, index) => (
                        <MenuItem key={index} value={option.value}>
                            {option.key}
                        </MenuItem>
                    ))}
                </TextField>
            )}
        />
    );
};

export default ControlledSelectField;