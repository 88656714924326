import React from 'react';
import {Button, CircularProgress} from "@mui/material";

const SubmitButton = (
    {
        text,
        onSubmit = null,
        disabled = false,
        loading = false,
        variant = "contained",
        color = "primary",
        type="submit",
        buttonRef=null,
        sx={}
    }) => {
    return (
        <Button
            type={type}
            fullWidth
            disabled={loading || disabled}
            variant={variant}
            color={color}
            onClick={onSubmit}
            ref={buttonRef}
            sx={sx}
        >
            {loading ? <>
                    <CircularProgress size={24}/>
                    &nbsp;
                </>
                : text}
        </Button>
    );
};

export default SubmitButton;