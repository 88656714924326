import React from 'react';
import {Controller} from "react-hook-form";
import {FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField} from "@mui/material";
import {DatePicker, LocalizationProvider, renderTimeViewClock, TimePicker} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";

const DatePickerField = ({value, onChange, label, error = null, inputProps = null, disabled = false}) => {
    return (

        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
                label={label}
                // viewRenderers={{
                //     hours: renderTimeViewClock,
                //     minutes: renderTimeViewClock,
                // }}
                // ampm={false}
                slotProps={{
                    textField: {
                        variant: "standard",
                        error: !!error,
                        helperText: !!error ? error.message : " "
                    }
                }}
                value={value}
                onError={(error, value) => console.log(error)}
                onChange={onChange}
            />
        </LocalizationProvider>

    );
};

export default DatePickerField;