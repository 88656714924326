import React from 'react';
import {Controller} from "react-hook-form";
import {FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField} from "@mui/material";
import {LocalizationProvider, renderTimeViewClock, TimePicker} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";

const ControlledTimePickerField = ({control, name, label, inputProps = null, disabled = false}) => {
    return (
        <Controller
            name={name}
            control={control}
            render={({field: {onChange, value}, fieldState: {error}}) => (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker
                        label={label}
                        viewRenderers={{
                            hours: renderTimeViewClock,
                            minutes: renderTimeViewClock,
                        }}
                        ampm={false}
                        slotProps={{ textField: { variant: "standard", error:!!error, helperText: !!error ? error.message : " " } }}
                        value={value}
                        onError={(error, value) => console.log(error)}
                        onChange={onChange}
                    />
                </LocalizationProvider>
            )}
        />
    );
};

export default ControlledTimePickerField;