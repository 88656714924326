import dayjs from "dayjs";

export const fromHoursToDateTime = (timeString) => {

// Split your time string into hours, minutes, and seconds
    const [hours, minutes, seconds] = timeString.split(':');

// Get the current date and set the hours, minutes, and seconds
    let date = dayjs();
    date = date.set('hour', hours);
    date = date.set('minute', minutes);
    date = date.set('second', seconds);
    return date
}

export const fromDateToHours = (dateTimeString) => {
    return dayjs(dateTimeString).format('HH:mm:ss')
}

export const formatDate = (dateTimeString) => {
    const date = dayjs(dateTimeString);
    return date.format('YYYY-MM-DD'); // Output format: "2023-07-28"
}

export const getDayProgress = (datetimeString) => {
     const datetime = dayjs(datetimeString);
    const secondsSinceMidnight = datetime.hour() * 3600 + datetime.minute() * 60 + datetime.second();
    const totalSecondsInADay = 24 * 60 * 60; // 86400
    return (secondsSinceMidnight / totalSecondsInADay) * 100;
}
