export const LOGIN_START = 'LOGIN_START';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT = 'LOGOUT';
export const FINISH_INITIAL_LOADING = 'FINISH_INITIAL_LOADING';

export const SET_AUTH_REDIRECT_PATH = 'SET_AUTH_REDIRECT_PATH';

export const CLEAR_AUTH_ERRORS = 'CLEAR_AUTH_ERRORS'

export const SET_SNACK = 'SET_SNACK'
export const REMOVE_SNACK = 'REMOVE_SNACK'