import React from 'react';
import {Container, Paper} from "@mui/material";

const AuthLayout = ({children}) => {
    return (
        <Container component="main" maxWidth="xs" sx={{
            height: "100vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
        }}>
            <Paper sx={{mt: {sm: 0, lg: 10}, p: 2}}>
                {children}
            </Paper>
        </Container>
    );
};

export default AuthLayout;