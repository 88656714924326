import buildClient from "../buildClient";
import {endpoints} from "../endpoints";

const createList = (accountId, externalListId) => {
    return new Promise((resolve, reject) => {
        const apiClient = buildClient()

        const data = {
            account_id: accountId,
            external_list_id: externalListId
        }

        apiClient.post(endpoints.list, data)
            .then((response) => {
                resolve(response.data)
            })
            .catch((errorResponse) => {
                reject(errorResponse.data)
            })
    })
}

const getLists = () => {
    return new Promise((resolve, reject) => {
        const apiClient = buildClient()

        apiClient.get(endpoints.list)
            .then((response) => {
                resolve(response.data)
            })
            .catch((errorResponse) => {
                reject(errorResponse.data)
            })
    })
}

const deleteList = (listId) => {
    return new Promise((resolve, reject) => {
        const apiClient = buildClient()

        apiClient.delete(`${endpoints.list}/${listId}`)
            .then((response) => {
                resolve(response.data)
            })
            .catch((errorResponse) => {
                reject(errorResponse.data)
            })
    })
}

export const contactListService = {
    createList,
    getLists,
    deleteList
}
