import buildClient from "../buildClient";
import {endpoints} from "../endpoints";
import {SnackService} from "../../utils/snackService";
import {routes} from "../../utils/routes";

const createGatherer = (source_list_id) => {
    return new Promise((resolve, reject) => {
        const apiClient = buildClient();

        const data = {
            source_list_id,
        };

        apiClient.post(endpoints.gatherer, data)
            .then((response) => {
                resolve(response.data);
            })
            .catch((errorResponse) => {
                reject(errorResponse.data);
            });
    });
};

const getGatherers = () => {
    return new Promise((resolve, reject) => {
        const apiClient = buildClient();

        apiClient.get(endpoints.gatherer)
            .then((response) => {
                resolve(response.data);
            })
            .catch((errorResponse) => {
                reject(errorResponse.data);
            });
    });
};

const updateGatherer = (id, active) => {
    return new Promise((resolve, reject) => {
        const apiClient = buildClient();

        const data = {
            active,
        };

        apiClient.put(`${endpoints.gatherer}/${id}`, data)
            .then((response) => {
                resolve(response.data);
            })
            .catch((errorResponse) => {
                reject(errorResponse.data);
            });
    });
};

const deleteGatherer = (id) => {
    return new Promise((resolve, reject) => {
        const apiClient = buildClient();

        apiClient.delete(`${endpoints.gatherer}/${id}`)
            .then((response) => {
                resolve(response.data);
            })
            .catch((errorResponse) => {
                reject(errorResponse.data);
            });
    });
};

export const gathererService = {
    createGatherer,
    getGatherers,
    updateGatherer,
    deleteGatherer,
};
