import React from 'react';
import {Box, CircularProgress, Grid, IconButton, Paper, Typography} from "@mui/material";
import CenteredLoader from "../Loaders/CenteredLoader";
import JobsTimeline from "./JobsTimeline";
import DatePickerField from "../DatePickerField";
import {KeyboardArrowLeft, KeyboardArrowRight} from "@mui/icons-material";
import dayjs from "dayjs";

const JobsChart = ({loading, jobs, date, onDateChange}) => {
    let content;

    if (loading) {
        content = <CenteredLoader/>
    } else if (jobs.length < 1) {
        content = <Box sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        }}>
            <Typography sx={{margin: "auto"}}>
                No Jobs Scheduled for this Day
            </Typography>
        </Box>
    } else {

        content = <JobsTimeline jobs={jobs} date={date}/>
    }


    const onIconClick = (days) => {
        onDateChange(date.subtract(days, 'day'))
    }

    return (
        <Paper sx={{p: 4}}>
            <Grid container alignItems={"center"}>
                <Grid item xs={3}>

                </Grid>
                <Grid item xs={1}>

                    <Box sx={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}>
                        <IconButton onClick={() => onIconClick(1)}>
                            <KeyboardArrowLeft/>
                        </IconButton>
                    </Box>
                </Grid>
                <Grid item xs={4}>
                    <Box sx={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}>
                        <DatePickerField label={"Date"} value={date} onChange={onDateChange}/>

                    </Box>
                </Grid>

                <Grid item xs={1}>
                    <Box sx={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}>
                        <IconButton onClick={() => onIconClick(-1)}>
                            <KeyboardArrowRight/>
                        </IconButton>
                    </Box>
                </Grid>
                <Grid item xs={3}>

                </Grid>
            </Grid>
            <Box sx={{height: 150}}>
                {content}
            </Box>
        </Paper>
    );
};

export default JobsChart;