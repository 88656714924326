import React, {useState} from 'react';
import {Button, IconButton} from "@mui/material";
import {Edit} from "@mui/icons-material";
import EditAccountModal from "./AccountModal/EditAccountModal";

const EditAccount = ({fetchAccounts, account}) => {
    const [modalOpen, setModalOpen] = useState(false)

    const handleModalClose = () => {
        setModalOpen(false)
    }
    const handleModalOpen = () => {
        setModalOpen(true)
    }


    return (
        <>
            <Button onClick={() => handleModalOpen()} color={"warning"} variant={"outlined"}>
                Edit
            </Button>
            <EditAccountModal open={modalOpen} handleClose={handleModalClose} fetchAccounts={fetchAccounts} account={account}/>
        </>
    );
};

export default EditAccount;