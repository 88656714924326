import React from 'react';
import {Controller} from "react-hook-form";
import {
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    Input,
    InputAdornment,
    InputLabel,
    TextField
} from "@mui/material";
import {Visibility, VisibilityOff} from "@mui/icons-material";

const ControlledPasswordField = ({control, name, label, showPassword, setShowPassword}) => {

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <Controller
            name={name}
            control={control}
            defaultValue=""
            render={({field: {onChange, value}, fieldState: {error}}) => (
                <FormControl variant={"standard"} fullWidth error={!!error}>
                    <InputLabel>{label}</InputLabel>
                    <Input
                        label={label}
                        value={value}
                        onChange={onChange}

                        type={showPassword ? "text" : "password"}
                        endAdornment={<InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => setShowPassword(!showPassword)}
                                onMouseDown={handleMouseDownPassword}
                            >
                                {showPassword ? <VisibilityOff/> : <Visibility/>}
                            </IconButton>
                        </InputAdornment>}
                    />
                    <FormHelperText>{error ? error.message : " "}</FormHelperText>
                </FormControl>)}
        />
    );
};

export default ControlledPasswordField;