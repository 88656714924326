import buildClient from "../buildClient";
import {endpoints} from "../endpoints";
import {SnackService} from "../../utils/snackService";
import {routes} from "../../utils/routes";


const login = (email, password) => {

    return new Promise((resolve, reject) => {
        const apiClient = buildClient()

        const data = {
            email,
            password
        }

        apiClient.post(endpoints.login, data)
            .then((response) => {
                resolve(response.data)
            })
            .catch((errorResponse) => {
                reject(errorResponse.data)
            })

    })
}



export const authService = {
    login,
}