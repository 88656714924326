import React from 'react';
import {Box, CircularProgress} from "@mui/material";

const CenteredLoader = () => {
    return (
        <Box sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        }}>
            <CircularProgress sx={{margin: "auto"}}/>
        </Box>
    );
};

export default CenteredLoader;