import React, {useState} from 'react';
import {Box, CircularProgress, Divider, Grid, IconButton, Typography} from "@mui/material";
import {ArrowDownward, ArrowUpward, Delete, Edit} from "@mui/icons-material";
import ToggleGathererState from "./ToggleSchedulerState";
import DeleteGatherer from "./DeleteScheduler";
import EditScheduler from "./EditScheduler";
import Status from "../Status";
import SchedulerDetails from "./SchedulerDetails";


const SchedulerListItem = ({scheduler, fetchSchedulers, contactLists}) => {
    const [showDetails, setShowDetails] = useState(false)

    const toggleDetails = () => {
        setShowDetails(!showDetails)
    }

    return (
        <Grid container item xs={24} alignItems={"center"} spacing={2} columns={24}>
            <Grid item xs={1}>
                {scheduler.id}
            </Grid>
            <Grid item xs={4}>
                <Typography
                    sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        width: '100%',
                    }}
                >
                    {`List ${scheduler.source_list.external_list_id} of Account ${scheduler.source_list.account.external_account_id}`}
                </Typography>
            </Grid>
            <Grid item xs={4}>
                <Typography
                    sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        width: '100%',
                    }}
                >
                    {`List ${scheduler.target_list.external_list_id} of Account ${scheduler.target_list.account.external_account_id}`}
                </Typography>
            </Grid>

            <Grid item xs={2}>
                {scheduler.target_items}
            </Grid>
            <Grid item xs={2}>
                {scheduler.start_time}
            </Grid>
            <Grid item xs={2}>
                {scheduler.end_time}
            </Grid>
            <Grid item xs={4}>
                {scheduler.last_execution}
            </Grid>
            <Grid item xs={2}>
                {scheduler.active ?
                    <Status size={24} color={"success.main"}/> :
                    <Status size={24} color={"error.main"}/>
                }
            </Grid>
            <Grid item xs={showDetails ? 3 : 1}>
                <EditScheduler
                    fetchSchedulers={fetchSchedulers}
                    scheduler={scheduler}
                    contactLists={contactLists}
                />
                {/*<ToggleGathererState scheduler={scheduler} fetchGatherers={fetchGatherers}/>*/}
            </Grid>
            {showDetails &&
                <Grid item xs={24}>
                    <SchedulerDetails scheduler={scheduler}/>
                </Grid>
            }
            <Grid item xs={showDetails ? 24 : 2} sx={{alignItems: "center"}}>
                <Box width={"100%"} sx={{textAlign: "center"}}>
                    <IconButton aria-label="delete" onClick={() => toggleDetails()}>
                        {showDetails ? <ArrowUpward/> : <ArrowDownward/>}
                    </IconButton>
                </Box>
            </Grid>

        </Grid>
    );
};

export default SchedulerListItem;