import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';

import FullPageLoader from "../components/Loaders/FullPageLoader";

import {setSnack} from "../store/actions/snack";
import {contactListService} from "../api/services/contactListService";
import PageTitle from "../components/PageTitle";
import {gathererService} from "../api/services/gathererService";
import AddGatherers from "../components/Gatherers/AddGatherers";
import GatherersList from "../components/Gatherers/GatherersList";
import {schedulerService} from "../api/services/schedulerService";
import AddScheduler from "../components/Schedulers/AddScheduler";
import SchedulersList from "../components/Schedulers/SchedulersList";


// Other imports like FullPageLoader, EmptyGatherers, GatherersList based on your application structure.

const Schedulers = ({setSnack}) => {
    const [loadingContactLists, setLoadingContactLists] = useState(true);
    const [contactLists, setContactLists] = useState([]);
    const [loadingSchedulers, setLoadingSchedulers] = useState(true);
    const [schedulers, setSchedulers] = useState([]);

    useEffect(() => {
        fetchSchedulers()
    }, [setSnack]);

    useEffect(() => {
        fetchContactLists()
    }, [setSnack]);

    const fetchSchedulers = () => {
        setLoadingSchedulers(true)
        schedulerService.getSchedulers()
            .then((response) => {
                setLoadingSchedulers(false);
                setSchedulers(response.data);
            })
            .catch((errorResponse) => {
                setLoadingSchedulers(false);
                setSnack(errorResponse.message, "error");
            });
    }

    const fetchContactLists = () => {
        setLoadingContactLists(true)
        contactListService.getLists()
            .then((response) => {
                setLoadingContactLists(false);
                setContactLists(response.data);
            })
            .catch((errorResponse) => {
                setLoadingContactLists(false);
                setSnack(errorResponse.message, "error");
            });
    }


    if (loadingSchedulers || loadingContactLists) {
        return <FullPageLoader/>;
    }

    return (
        <>
            <PageTitle title={"Schedulers"}/>
            <AddScheduler fetchSchedulers={fetchSchedulers} contactLists={contactLists}/>
            <SchedulersList schedulers={schedulers} fetchSchedulers={fetchSchedulers} contactLists={contactLists}/>
        </>
    );
};

const mapDispatchToProps = dispatch => {
    return {
        setSnack: (message, severity) => dispatch(setSnack(message, severity))
    };
};

export default connect(null, mapDispatchToProps)(Schedulers);
