import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import AccountsList from "../components/Accounts/AccountsList";
import FullPageLoader from "../components/Loaders/FullPageLoader";
import EmptyAccounts from "../components/Accounts/EmptyAccounts";
import {setSnack} from "../store/actions/snack";
import {accountService} from "../api/services/accountService";
import PageTitle from "../components/PageTitle";
import AddAccount from "../components/Accounts/AddAccount";

// Other imports like FullPageLoader, EmptyAccounts, AccountsList based on your application structure.

const Accounts = ({setSnack}) => {
    const [loading, setLoading] = useState(true);
    const [accounts, setAccounts] = useState([]);

    useEffect(() => {
        fetchAccounts()
    }, [setSnack]);

    const fetchAccounts = () => {
        setLoading(true)
        accountService.getAccounts()
            .then((response) => {
                setLoading(false);
                setAccounts(response.data);
            })
            .catch((errorResponse) => {
                setLoading(false);
                setSnack(errorResponse.message, "error");
            });
    }

    if (loading) {
        return <FullPageLoader/>;
    }

    return (
        <>
            <PageTitle title={"Accounts"}/>
            <AddAccount fetchAccounts={fetchAccounts}/>
            <AccountsList accounts={accounts} fetchAccounts={fetchAccounts}/>
        </>
    );
};

const mapDispatchToProps = dispatch => {
    return {
        setSnack: (message, severity) => dispatch(setSnack(message, severity))
    };
};

export default connect(null, mapDispatchToProps)(Accounts);
