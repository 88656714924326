import React from 'react';
import {Container, Divider, Grid} from "@mui/material";
import AccountsListItem from "./AccountsListItem";

const AccountsList = ({accounts, fetchAccounts}) => {
    return (
        <Container maxWidth={"lg"}>
            <Grid container spacing={2} alignItems={"center"}>
                <Grid item xs={1}>
                    ID
                </Grid>
                <Grid item xs={2}>
                    Maropost ID
                </Grid>
                <Grid item xs={6}>
                    API KEY
                </Grid>
                <Grid item xs={2}>
                </Grid>
                <Grid item xs={12}>
                    <Divider/>
                </Grid>
                <Grid container item xs={12} spacing={1} >
                    {accounts.map((account, index) => {
                        return <AccountsListItem key={index} account={account} fetchAccounts={fetchAccounts}/>
                    })}
                </Grid>
            </Grid>

        </Container>
    )
};

export default AccountsList;