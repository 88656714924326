import React, {useEffect} from 'react';
import NavigationBar from "./NavigationBar/NavigationBar";
import {Box, Toolbar, Container} from "@mui/material";
import {Outlet, useNavigate} from "react-router-dom";
import {drawerWidth} from "../utils/drawerSettings";
import {checkAuthState, setAuthRedirectPath} from "../store/actions/auth";
import {connect} from "react-redux";
import {routes} from "../utils/routes";
import FullPageLoader from "./Loaders/FullPageLoader";

const AppLayout = ({props, children, isAuthenticated}) => {
    const navigate = useNavigate()

    useEffect(() => {
        if (!isAuthenticated) {
            navigate(routes.login)
        }
    }, [isAuthenticated])


    if (!isAuthenticated) {
        return <FullPageLoader/>
    }

    return (
        <Box sx={{display: 'flex'}}>

            <Box
                component="nav"
                sx={{width: {sm: drawerWidth}, flexShrink: {sm: 0}}}
                aria-label="mailbox folders"
            >

                <NavigationBar/>

            </Box>
            <Box
                component="main"
                sx={{flexGrow: 1, p: 3, width: {sm: `calc(100% - ${drawerWidth}px)`}}}
            >

                <Outlet/>


            </Box>
        </Box>
    );
};

const mapStateToProps = state => {
    return {
        isAuthenticated: !!state.auth.token
    };
};


const mapDispatchToProps = dispatch => {
    return {
        clearAuthRedirectPath: () => dispatch(setAuthRedirectPath(undefined)),
        checkAuthState: () => dispatch(checkAuthState()),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(AppLayout);